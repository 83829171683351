<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields($dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE)"
      @submit="handleQueryFrom"
      @reset="handleQueryFrom"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
    >
      <template #id="{row}">
        <nh-button
          type="text"
          @click="handleDetails(row)"
        >
          {{ row.id }}
        </nh-button>
      </template>
      <!-- <template #attrCode="{row}">
        <span>
          {{ filterLabel(row.attrCode, warehouseAttr) }}</span>
      </template> -->
      <template #action="{row}">
        <nh-button
          type="text"
          @click="handleset(row)"
        >
          默认配置
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <Detail ref="Detail" />
  <el-dialog v-model="dialogFormVisible" title="默认配置">
    <el-form :model="form">
      <el-form-item
        label="盘点方式"
        label-width="150px"
      >
        <el-radio-group v-model="form.bizConfig.countOperationType">
          <el-radio label="PDA">
            PDA
          </el-radio>
          <el-radio label="PAPER">
            纸单
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="移库作业方式"
        label-width="150px"
      >
        <el-radio-group v-model="form.bizConfig.transferOperationType">
          <el-radio label="PDA">
            PDA
          </el-radio>
          <el-radio label="PAPER">
            纸单
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="初盘差异是否强制复盘"
        label-width="150px"
      >
        <el-radio-group v-model="form.bizConfig.isRecount">
          <el-radio :label="true">
            是
          </el-radio>
          <el-radio :label="false">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="库存调整是否审核"
        label-width="150px"
      >
        <el-radio-group v-model="form.bizConfig.isAuditAdjust">
          <el-radio :label="true">
            是
          </el-radio>
          <el-radio :label="false">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer class="dialog-footer">
      <el-button @click="dialogFormVisible = false">
        取 消
      </el-button>
      <el-button type="primary" @click="handleSure">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';
import Base from '@/constant/pageNames/base';
import Detail from './details/index.vue';
import { TABLECOLUMN, serchFields } from './fileds';
import mixin from './mixin';
import { warehousePage, warehouseSet } from './api';

export default {
  name: Base.WAREHOUSE,
  components: { Detail },
  mixins: [loadingMixin, mixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      formData: {
        id: this.$store.state.user.accountInfo.warehouseId,
      },
      tableData: [],
      loading: {
        getTableData: false,
      },
      dialogFormVisible: false,
      form: {
        id: '',
        bizConfig: {
          countOperationType: 'PAPER',
          transferOperationType: 'PAPER',
          isRecount: false,
          isAuditAdjust: false,
        },
      },

    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleQueryFrom() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await warehousePage(pagination, this.formData);
      this.tableData = resp.records.map((item) => ({
        ...item,
        temperature: item.temperatureLayer.map((temp) => temp.temperatureLayerName).join('、'),
      }));
      this.pagination.total = resp.total;
    },
    filterLabel(val, arr) {
      const filertArr = arr && arr.find((item) => item.value === val);
      return filertArr && filertArr.label;
    },
    handleDetails(row) {
      this.$refs.Detail.init({ id: row.id });
    },
    handleset(row) {
      this.form.id = row.id;
      this.form.bizConfig = row.bizConfig || {
        countOperationType: 'PAPER',
        transferOperationType: 'PAPER',
        isRecount: false,
        isAuditAdjust: false,
      };
      this.dialogFormVisible = true;
    },
    async handleSure() {
      await warehouseSet(this.form);
      this.$message.success('操作成功!');
      this.dialogFormVisible = false;
      this.getTableData();
    },
  },
};
</script>
<style scoped>
::v-deep .el-radio-group {
  width: 200px;
  display: flex;

}
::v-deep .el-radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  margin-left: 10px;
}
</style>
