<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.getDetail"
    :before-close="handleClose"
    destroy-on-close
    title="仓库信息"
    size="1112px"
  >
    <nh-drawer-container title="仓库基础信息">
      <div class="body store_info">
        <el-row :gutter="20">
          <el-col
            v-for="(item, index) in StoreInfo"
            :key="index"
            :span="8"
          >
            <el-form label-width="100px" label-position="left">
              <el-form-item :label="item.label">
                <template v-if="item.prop === 'attrCode'">
                  {{ filterLabel(StoreData[item.prop], warehouseAttr) }}
                </template>
                <template v-else>
                  {{ StoreData[item.prop] }}
                </template>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </nh-drawer-container>
    <nh-drawer-container title="仓库运营信息">
      <div class="body">
        <div class="sub_title">
          <h4>每日收货时间范围</h4>
        </div>
        <div class="body">
          <el-row
            v-for="(item,index) in receiving"
            :key="index"
          >
            <el-col
              :xs="12"
              :sm="12"
              :md="8"
              :lg="6"
            >
              <el-form label-width="100px" label-position="left">
                <el-form-item label="温层:">
                  {{ filterLabel(item.temp, zoneTemperature) }}
                </el-form-item>
              </el-form>
            </el-col>
            <el-col
              :xs="12"
              :sm="12"
              :md="8"
              :lg="6"
            >
              <el-form label-width="100px" label-position="left">
                <el-form-item label="时间范围:">
                  {{ item.time }}
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="body">
        <div class="sub_title">
          <h4>每日发货时间范围</h4>
        </div>
        <div class="body">
          <el-row
            v-for="(item,index) in delivery"
            :key="index"
          >
            <el-col
              :xs="12"
              :sm="12"
              :md="8"
              :lg="6"
            >
              <el-form label-width="100px" label-position="left">
                <el-form-item label="温层:">
                  {{ filterLabel(item.temp, zoneTemperature) }}
                </el-form-item>
              </el-form>
            </el-col>
            <el-col
              :xs="12"
              :sm="12"
              :md="8"
              :lg="6"
            >
              <el-form label-width="100px" label-position="left">
                <el-form-item label="时间范围:">
                  {{ item.time }}
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </div>
    </nh-drawer-container>
    <nh-drawer-container title="计费信息">
      <div class="body store_info">
        <el-form label-position="left">
          <div
            v-for="(item, infoIndex) in chargeInfosData"
            :key="infoIndex"
            class="flex-infos"
          >
            <el-form-item
              v-for="info in chargeInfos"
              :key="info.prop"
              :label="info.label"
            >
              <span>
                {{ item[info.prop] }}
              </span>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </nh-drawer-container>
  </nh-drawer>
</template>

<script>
import loadingMixin from '@thales/loading';
import { StoreInfo, chargeInfos } from './fileds';
import mixin from '../mixin';
import { warehouseGet } from '../api';

export default {
  name: 'WarehouseDetails',
  mixins: [mixin, loadingMixin],
  data() {
    return {
      StoreInfo,
      chargeInfos,
      drawerVisible: false,
      StoreData: {},
      receiving: [],
      delivery: [],
      chargeInfosData: [],
      loading: {
        getDetail: false,
      },
    };
  },
  methods: {
    async init(row) {
      this.drawerVisible = true;
      const { id } = row;
      this.getDetail({ id });
    },
    async getDetail(data) {
      const {
        deliverTime: delivery,
        storeTime: receiving,
        chargeInfos: chargeInfosData,
        ...StoreData
      } = await warehouseGet(data);
      this.delivery = delivery.map((item) => ({
        temp: item.temperatureLayerCode,
        time: `${item.startTime} - ${item.endTime}`,
      }));
      this.receiving = receiving.map((item) => ({
        temp: item.temperatureLayerCode,
        time: `${item.startTime} - ${item.endTime}`,
      }));
      this.StoreData = {
        ...StoreData,
        temperature: StoreData.temperatureLayer.map((temp) => temp.temperatureLayerName).join('、'),
        range: `${StoreData.temperatureMin} - ${StoreData.temperatureMax}`,
        province: StoreData.provinceName ? `${StoreData.provinceName} - ${StoreData.cityName} - ${StoreData.countyName}` : '',
      };
      this.chargeInfosData = chargeInfosData.map((item) => ({
        ...item,
        temperature: `${item.minTemperature}-${item.maxTemperature}`,
      }));
    },
    filterLabel(val, arr) {
      const filertArr = arr && arr.find((item) => item.value === val);
      return filertArr && filertArr.label;
    },
    handleClose() {
      this.drawerVisible = false;
      this.StoreData = {};
      this.receiving = [];
      this.delivery = [];
      this.chargeInfosData = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.details-page {
  min-width: 768px;
}

.block {
  :deep(.el-form-item) {
    margin: 0;
  }
}

.title {
  border-left: 3px solid #02a7f0;
  padding-left: 10px;

  h3 {
    font-size: 14px;
  }
}

.store_info {
  margin-top: 20px;

  .flex-infos {
    display: flex;

    .el-form-item {
      flex: 1 0 200px;
    }
  }
}

.body {
  padding-left: 40px;
}

.sub_title {
  margin-top: 20px;
  padding: 4px 32px 4px 4px;
  display: inline-block;
  transform: skew(-25deg); /* 设置倾斜度为-30 */
  -webkit-transform: skew(-25deg);
  -moz-transform: skew(-25deg);
  -o-transform: skew(-25deg);
  -ms-transform: skew(-25deg);
  background-color: #f2f2f2;

  h4 {
    font-weight: normal;
    transform: skew(25deg);
    -webkit-transform: skew(25deg);
    -moz-transform: skew(25deg);
    -o-transform: skew(25deg);
    -ms-transform: skew(25deg);
  }
}
</style>
