import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 分页查询仓库
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11192
 */

export function warehousePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse/page', data, {
    params,
  });
}
/**
 * @description: 获取仓库详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11186
 */

export function warehouseGet(params) {
  const { id } = params;
  return wmsPlusHttp.get(`/warehouse_foundation/warehouse/get?id=${id}`);
}

/**
 * @description: 仓库设置配置
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/21181
 */

export function warehouseSet(data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse/update/config', data);
}
