export default {
  computed: {
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
    warehouseAttr() {
      return this.$dictionaryFiledMap.WAREHOUSE_ATTR_CODE || [];
    },
  },
};
