import { FormType } from '@/constant/form';

const WAREHOUSE_STATUS = [{
  label: '启用',
  value: '1',
}, {
  label: '禁用',
  value: '0',
}];

const DICTIONARY = {
  open: 'open',
  block_up: 'block_up',
};
// const filterLabel = (val, arr) => {
//   const filertArr = arr && arr.find((item) => item.value === val);
//   return filertArr && filertArr.label;
// };
export {
  WAREHOUSE_STATUS, DICTIONARY,
};
export const TABLECOLUMN = [
  {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '仓库ID',
    prop: 'id',
    minWidth: 120,
    url: 'warehouse/details',
  }, {
    label: '仓库编码',
    prop: 'warehouseCode',
    minWidth: 120,
  }, {
    label: '仓库名称',
    prop: 'warehouseName',
    minWidth: 120,
  },
  // {
  //   label: '状态',
  //   prop: 'statusCode',
  //   minWidth: 120,
  //   filter: true,
  //   arr: WAREHOUSE_STATUS,
  //   formatter: (row) => filterLabel(row.statusCode, WAREHOUSE_STATUS),
  // },
  {
    label: '支持温层',
    prop: 'temperature',
    minWidth: 180,
  },
  //  {
  //   label: '仓库属性',
  //   prop: 'attrCode',
  //   minWidth: 180,
  // },

  {
    label: '仓库面积',
    prop: 'area',
    minWidth: 120,
  }, {
    label: '仓库地址',
    prop: 'address',
    minWidth: 180,
  },
  {
    label: '操作',
    prop: 'action',
    fixed: 'right',
  },
];
export function serchFields(zoneTemperature) {
  return [
    {
      label: '仓库ID/编码',
      prop: 'code',
      component: FormType.INPUT,
    }, {
      label: '仓库名称',
      prop: 'name',
      component: FormType.INPUT,
    },
    //  {
    //   label: '状态',
    //   prop: 'statusCode',
    //   component: FormType.SELECT,
    //   options: WAREHOUSE_STATUS,
    // },
    {
      label: '支持温层',
      prop: 'temperatureLayerName',
      component: FormType.SELECT,
      options: zoneTemperature,
    },
  ];
}
