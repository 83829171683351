const StoreInfo = [{
  label: '仓库ID：',
  prop: 'id',
}, {
  label: '仓库名称：',
  prop: 'warehouseName',
}, {
  label: '仓库编码：',
  prop: 'warehouseCode',
}, {
  label: '支持温层：',
  prop: 'temperature',
}, {
  label: '制冷区间：',
  prop: 'range',
}, {
  label: '仓库面积：',
  prop: 'area',
}, {
  label: '联系人：',
  prop: 'linkMan',
}, {
  label: '联系人电话：',
  prop: 'telephone',
}, {
  label: '省市区：',
  prop: 'province',
}, {
  label: '地址：',
  prop: 'address',
}];
const chargeInfos = [{
  label: '温层：',
  prop: 'temperatureLayerName',
}, {
  label: '面积(m²)：',
  prop: 'area',
}, {
  label: '制冷区间：',
  prop: 'temperature',
}, {
  label: '计费单价：',
  prop: 'price',
}, {
  label: '计费单位：',
  prop: 'unit',
}];
export { StoreInfo, chargeInfos };
